/*
Filename: application/frontend/lib/validations.js
Description: Validation functions for NIF, email, etc
Author: Aires Marques
History:
2023-10-22 - Moved into this utility functions file

Notes:
*/

import { backend } from '../authConfig';

function validateEmail(email) {
  // Regex for basic email validation
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Check if the email passes the regex test
  if (!re.test(String(email).toLowerCase())) {
    return Promise.resolve(false); // Immediately resolve to false if regex fails
  }

  // If regex test passes, proceed to backend validation
  const url = `${backend.url_get_aux_validate_email}?email=${encodeURIComponent(email)}`;

  // Return the fetch promise for chaining
  return fetch(url, {
    method: 'GET', // or 'POST' if your backend requires it
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }
    return response.json();
  })
  .then(data => {
    // Assuming the backend returns a JSON object with a boolean 'isValid' key
    return data.isValid;
  })
  .catch(error => {
    console.error('There has been a problem with your fetch operation:', error);
    return false; // or handle the error as appropriate for your use case
  });
}


// Helper function to validate Portuguese VAT tax ID (NIF)
function validateNif(nif) {
  const re = /^\d{9}$/;
  if (!re.test(nif)) {
    return false;
  }

  // Accept the default generic NIF
  if (nif === "999999990") {
    return true;
  }

  // Check if the first digit corresponds to valid mode prefixes
  const firstDigit = nif.slice(0, 2);
  const validPrefixes = ['1', '2', '3', '45', '5', '6', '70', '74', '75', '71', '72', '77', '78', '90', '91', '98', '99'];
  
  // We need to check for the two-digit prefixes first
  if (!validPrefixes.includes(firstDigit) && !validPrefixes.includes(nif[0])) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum += nif[i] * (9 - i);
  }

  const remainder = sum % 11;
  const checkDigit = remainder < 2 ? 0 : 11 - remainder;

  return nif[8] === checkDigit.toString();
}

export { validateEmail, validateNif };