// src/contexts/AppConfigContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAllProductsArray } from './ProductData';

export const AppConfigContext = createContext(null);

export const AppConfigProvider = ({ children }) => {
  const [productData, setProductData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProductData = async () => {
      setIsLoading(true);
      try {
        const data = await getAllProductsArray();
        setProductData(data);
      } catch (error) {
        console.error('Could not fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductData();
  }, []);

  const value = { 
    productData, 
    isLoading,
    setProductData
  };

  return (
    <AppConfigContext.Provider value={value}>
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = () => useContext(AppConfigContext);