
/*
 * Filename: application/frontend/src/pages/LocationChooser.js
 * Description: LocationChooser component
 * Author: Aires Marques
 * History:
 + 2023-10-26 - Documented
 * Notes:
*/



import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { fetchLocations } from '../lib/getLocationData';

const LocationChooserPage = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const getLocations = async () => {
      const locationsData = await fetchLocations();
      setLocations(locationsData);
    };
  
    getLocations();
  }, []);

  const handleLocationSelection = (locationId) => {
    navigate(`/location/${locationId}`);
  };

  return (
    <div className="laundry-chooser">
      <h1>Select a Laundry Location</h1>
      <div>
        {locations.map(location => (
          <button key={location.laundry_id} onClick={() => handleLocationSelection(location.laundry_id)}>
            {location.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default LocationChooserPage;
