import React from 'react';

const PaymentUnavailableMessage = () => (
  <div className="unavailable-container">
    <img src="/pagalava_outoforder.webp" alt="Sistema Indisponível" />
      <h1>Sistema de pagamento em manutenção.  A versão nova vai ser incrível!</h1>
      <h2>Hoje poderá pagar com dinheiro. É uma seca, mas funciona.</h2>
      <br/>
      <h1>Payment system under maintenance. The new version will be awesome!</h1>
      <h2>Today you can pay with cash. Boring, but it works.</h2>
      <br/>
  </div>
);

export default PaymentUnavailableMessage;
