import { useEffect, useState, useContext } from 'react';
import { AppConfigContext } from '../contexts/AppConfigContext'; // Import the context

/**
 * useProduct: Custom hook to fetch and return the product based on the provided locationId and productNumber.
 *
 * @param {string} locationId - The location ID.
 * @param {string} productNumber - The product number.
 * @returns {Object|null|Error} - The product object or null if not found or an Error object if an error occurs.
 */
export default function useProduct(locationId, productNumber) {
  const [error, setError] = useState(null);
  const { productData } = useContext(AppConfigContext); // Use the context

  useEffect(() => {
    console.log('useProduct: locationId:', locationId);
    console.log('useProduct: productNumber:', productNumber);
    if (!productData || !productData[locationId]) {
      setError(new Error('Product not found or data not loaded'));
    } else {
      setError(null); // Reset error if data is available
    }
  }, [locationId, productNumber, productData]);

  if (error) {
    return error;
  }

  if (!productData || !productData[locationId]) {
    return null;
  }

  const products = productData[locationId];
  const selectedProduct = products.find(
    (product) => product.number === parseInt(productNumber)
  );

  return selectedProduct;
}
