import React, { useEffect } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppState } from '../contexts/AppStateContext';

import useProduct from '../hooks/useProduct';

const DryOptions = () => {
    const { locationId, productNumber } = useParams();
    const navigate = useNavigate();
        const product = useProduct(locationId, productNumber);
    const { cycles, setState: setAppState } = useAppState();
    const timeStep = product && product.time_minutes !== undefined ? product.time_minutes : 12;


    const minCycles = 1;

    useEffect(() => {
        setAppState(prevState => ({ ...prevState, cycles: 2 }));
    }, [setAppState]);


    const handleTimeChange = (increment) => {
        setAppState(prevState => {
            const newCycles = increment ? prevState.cycles + 1 : prevState.cycles - 1;
            return {
                ...prevState,
                cycles: newCycles < minCycles ? minCycles : newCycles
            };
        });
    };

    const handleContinue = () => {
        navigate(`/location/${locationId}/pay-options/${productNumber}`);
        console.log('Continue with:', cycles * timeStep, 'minutes');
    };

    if (!product) {
        return <div>A carregar...</div>;
    }

    return (
        <Card className="product-card">
            <Card.Body>
                <Card.Title>Tempo de Secagem</Card.Title>
                <Card.Subtitle className="mb-2">{product.title} {product.number} - {product.capacity}Kg</Card.Subtitle>
                <Card.Text>€{product.price * cycles}</Card.Text>
                <Form>
                    <Row className="align-items-center">
                        <Col xs="auto">
                            <Button
                                variant='outline-primary'
                                onClick={() => handleTimeChange(false)}
                                disabled={cycles === minCycles}
                            >
                                -
                            </Button>
                        </Col>
                        <Col xs="auto">
                            <span className="dry-options-display">{cycles * timeStep} minutos</span>
                        </Col>
                        <Col xs="auto">
                            <Button
                                variant='outline-primary'
                                onClick={() => handleTimeChange(true)}
                            >
                                +
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Button 
                                variant="primary"
                                onClick={handleContinue}
                            >
                                Continuar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default DryOptions;
