import { Card, Row, Col } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';

function ProductCard(props) {
  const product = props.product;
  const isEnabled = product.state === 'on';

  const cardClass = isEnabled ? "product-card" : "product-card disabled";
  const badgeClass = isEnabled ? "product-badge" : "product-badge disabled";
  const handleClick = () => {
    if (isEnabled) {
      props.onCartClick(product.id);
    }
  };
  return (
    <Card className={cardClass}>
      <Card.Body onClick={handleClick} style={{ cursor: isEnabled ? 'pointer' : 'default' }}>
        <Row className="align-items-center">
          <Col xs={1}>
            <div className={badgeClass}>{product.number}</div>
          </Col>
          <Col xs={4}>
            <Card.Text>
              {product.title} - {product.capacity} Kg
            </Card.Text>
          </Col>
          <Col xs={4}>
            <Card.Text>
                {isEnabled ? `€${product.price}` : 'Indisponível'}            
            </Card.Text>
          </Col>
          <Col xs={3} className="justify-button-right">
            <i
              className="fa fa-shopping-cart fa-lg"
              aria-hidden="true"
            ></i>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ProductCard;
