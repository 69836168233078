// setChatCustomAttributes.js


/**
 * Sets custom attributes for a conversation after a successful payment.
 * @param {string} locationId - The location ID.
 * @param {number} productNumber - The product number.
 * @param {number} [quantity=1] - The quantity of the product, defaults to 1.
 * @param {number} [retryCount=0] - Current retry attempt, defaults to 0.
 * @param {number} [maxRetries=10] - Maximum number of retries, defaults to 10.
 * @return {Promise<void>}
 */

const setChatCustomAttributes = async (
  locationId,
  productNumber,
  quantity = 1,
  retryCount = 0,
  maxRetries = 10) => {


  console.log('setChatCustomAttributes', locationId, productNumber, quantity, retryCount, maxRetries);

  try {
    if (window.$chatwoot) {
      // window.$chatwoot.setConversationCustomAttributes({
      //   "locationId": locationId,
      //   "purchases": JSON.stringify([
      //     { 
      //       productNumber: productNumber,
      //       quantity : quantity
      //     }
      //   ]
      //   )
      // });
      window.$chatwoot.setCustomAttributes({
        "locationId": locationId,
        "purchases": JSON.stringify([
          { 
            productNumber: productNumber,
            quantity : quantity,
          }
          // more products...
        ]
        )
      });
    } else if (retryCount < maxRetries) {
      setTimeout(() => {
          setChatCustomAttributes(locationId, productNumber, quantity, retryCount + 1, maxRetries);
      }, 1000 * (retryCount + 1));
    }
        
    console.log('Custom attributes set successfully.');
    } catch (error) {
      console.error('Error setting custom attributes:', error);
    }

  };
  
  export default setChatCustomAttributes

  