// ./src/pages/SuccessDry.js

import React from 'react';

function SuccessDry() {
  return (
    <div style={{ padding: '20px' }}>
      <h2 className="card-text black-subheading">
        Instruções:
      </h2>
      
      {/* Instruction List */}
      <ul className="card-text black-list">
        <li>Escolha o programa na máquina de secar e pressione “Start”.</li>
        <li>Para alterar o programa, tirar roupa, ou parar a máquina, pode abrir (puxando) a porta a qualquer momento.</li>
        <li>Para evitar vincos, não sobrecarregue a máquina.</li>
        <li>Não coloque plásticos, borracha ou similares na secadora. Podem derreter e estragar a sua roupa.</li>
      </ul>
  
      <h1 className="card-subtitle black-subtitle">
        Boa Secagem 🎉
      </h1>

    </div>
  );
}

export default SuccessDry;