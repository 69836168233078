
/*
 * Filename: application/frontend/src/components/CustomerInfoRegistration.jsx
 * Description: Allows customers who use the cashier to register their informatiokn
 * and receive invoice/receipts by email
 * Author: Aires Marques
 * History:
 + 2023-10-25 - Created
 * Notes:
*/

import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useState } from 'react';
import { validateEmail, validateNif } from '../lib/validations';
import { useEffect } from 'react';


function CustomerInfoRegistration() {
  // Form fields state
  const [nif, setNif] = useState(() => {
    return localStorage.getItem('nif') || '';
  });
  const [isValidNif, setIsValidNif] = useState(false);

  const [email, setEmail] = useState(() => {
    return localStorage.getItem('email') || '';
  });
  const [isValidEmail, setIsValidEmail ] = useState(false);

  const [name, setName] = useState(() => {
    return localStorage.getItem('name') || '';
  });

  useEffect(() => {
    localStorage.setItem('nif', nif);
  }, [nif]);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem('name', name);
  }, [name]);

  useEffect(() => {
    if(email) {
      setIsValidEmail(validateEmail(email));
    } else {
      setIsValidEmail(false);
    }
  }, [email]);

  useEffect(() => {
    if(nif) {
      setIsValidNif(validateNif(nif));
    } else {
      setIsValidNif(false);
    }
  }, [nif]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting user registration form...');

    let invalidReasons = [];
    if (nif && !isValidNif) {
      invalidReasons.push("NIF is invalid");
    }
    if (email && !isValidEmail) {
      invalidReasons.push("Email is invalid");
    }

    if (invalidReasons.length > 0) {
      console.log('Invalid form due to the following reasons:', invalidReasons.join(', '));
      return;
    }

    // Process the registration logic here
  };

  return (
    <Card className="product-card">
      <Card.Body>
        <Card.Title>Registo de cliente</Card.Title>
        <Card.Subtitle> Receba as faturas por email quando usa notas/moedas</Card.Subtitle>
        <Card.Subtitle> Associe o NIF a um email </Card.Subtitle>
        <br/>
        <Form onSubmit={handleSubmit}>
          <Row style={{ marginBottom: '1rem' }}>
            <Col>
              <Form.Group controlId="name">
                <Form.Label>Nome:</Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginBottom: '1rem' }}>
            <Col>
              <Form.Group controlId="nif">
                <Form.Label>NIF:</Form.Label>
                <Form.Control
                  type="text"
                  value={nif}
                  onChange={(e) => setNif(e.target.value)}
                  onBlur={() => setIsValidNif(validateNif(nif))}
                  required
                />
                {nif && !isValidNif && (
                  <Form.Text className="text-danger">
                    Introduza um NIF válido.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginBottom: '1rem' }}>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => setIsValidEmail(validateEmail(email))}
                  required
                />
                {email && !isValidEmail && (
                  <Form.Text className="text-danger">
                    Please enter a valid email address.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="primary"
            type="submit"
            className="mt-3"
            disabled={
              !isValidNif || !isValidEmail
            }>Register
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default CustomerInfoRegistration;
