const MachineUnavailableMessage = () => (
    <div className="unavailable-container">
        <img src="/machine_outoforder.webp" alt="Out of Order" />
        <h1>Desculpe, esta máquina está temporariamente indisponivel.</h1>
        <h2>Voltará a funcionar quando estiver a funcionar.</h2>
        <br/><br/>
  
        <h1>Sorry, this machine is temporarily out of order.</h1>
        <h2>It will be working again when it's working.</h2>
    </div>
  );

export default MachineUnavailableMessage;
