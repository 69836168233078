// ProductData.js
import { app_location_data } from '../authConfig';

const processProduct = (product) => {
    const number = product.index;
    const title = product.type === 'wash' ? `LAVAR` : `SECAR`;
    // I want to upper case the product.type
    
    const productType = product.type.toUpperCase();
    const price = parseFloat(product.price);
    const time_minutes = product.time_minutes;
    const capacity = product.capacity;
    return {
        machineId: product.machineId,
        number,
        title,
        type: productType,
        price,
        time_minutes,
        capacity,
        state: product.state
    };
};

const fetchLaundryData = async () => {
    // Calculate cache busting value that changes every hour
    const cacheBuster = Math.floor(Date.now() / (1000 * 60 * 60));
    // Every minute
    //const cacheBuster = Math.floor(Date.now() / (1000 * 60));
    const url = `${app_location_data.url}?cb=${cacheBuster}`;
    
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching laundry data:', error);
        throw error; // Re-throw the error to handle it in the calling function
    }
};

const getProductsArray = async (locationId) => {
    const data = await fetchLaundryData();
    const products = data[locationId.toString()];
    if (!products) {
        throw new Error(`No data for location ${locationId}`);
    }
    return products.map(processProduct);
};

const getProductData = async (number, locationId) => {
    const productsArray = await getProductsArray(locationId);
    return productsArray.find(product => product.number === number);
};

const getAllProductsArray = async () => {
    const data = await fetchLaundryData();
    let allProductsByLocation = {};
    for (let locationId in data) {
        const products = data[locationId];
        if (products) {
            allProductsByLocation[locationId] = products.map(processProduct);
        }
    }
    return allProductsByLocation;
};

export {
    getProductData,
    getProductsArray,
    getAllProductsArray
};
