// ./contexts/AppStateContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AppStateContext = createContext(null);

const initialState = {
  paymentMethod: null,
  cycles: 1,
  locationId: null,
  sessionUUID: null,
};

export const AppStateProvider = ({ children }) => {
    const [state, setState] = useState(() => {
        const storedUuid = localStorage.getItem('sessionUUID') || uuidv4();
        return {
            ...initialState,
            sessionUUID: storedUuid,
        };
    });

    useEffect(() => {
        if (!localStorage.getItem('sessionUUID')) {
            localStorage.setItem('sessionUUID', state.sessionUUID);
        }
    }, [state.sessionUUID]);
    return (
        <AppStateContext.Provider value={{ ...state, setState }}>
            {children}
        </AppStateContext.Provider>
    );
};

export const useAppState = () => useContext(AppStateContext);
