
/*
 * Filename: application/frontend/src/getConfig.js
 * Description: Gets the configuration of the app
 * If .env variables are set, use them with precedence
 * Author: Aires Marques
 * History:
 + 2023-10-26 - Documented
 * Notes:
*/


import { app_vars } from './authConfig';

export async function fetchConfig() {
    const timestamp = Date.now();
    const configURL = `${app_vars.url}?nocache=${timestamp}`;
    const response = await fetch(configURL);
    if (!response.ok) {
        throw new Error('Failed to fetch app configuration');
    }
    const configJson = await response.json();

    // Here, you can override the values in configJson with .env values
    // For example, if you have a variable named REACT_APP_MY_VARIABLE in .env:
    //if (process.env.REACT_APP_CHATBOT_ENABLED) {
    //    configJson.REACT_APP_CHATBOT_ENABLED = process.env.REACT_APP_CHATBOT_ENABLED;
    //}


    return configJson;
}
