// ./src/pages/SuccessWash.js

import React from 'react';

function SuccessWash() {
  return (
    <div style={{ padding: '20px' }}>
      {/* Subheading */}
      <h2 className="card-text black-subheading">
        Instruções:
      </h2>
      
      {/* Instruction List */}
      <ul className="card-text black-list">
        <li>Coloque a roupa na máquina e feche a porta.</li>
        <li>Selecione o programa de lavagem e pressione “Start”.</li>
      </ul>
  
      {/* Main Heading */}
      <h1 className="card-subtitle black-subtitle">
        Boa Lavagem 🎉
      </h1>
    </div>
  );
}

export default SuccessWash;