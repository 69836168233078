// getLocationData.js
import { app_location_definition } from '../authConfig';

export async function fetchLocations() {
  try {
    const response = await fetch(app_location_definition.url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching locations:', error);
    return []; // Return an empty array in case of an error
  }
}
