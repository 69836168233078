//src/hooks/useChatwoot.js

import { useEffect } from 'react';
import { chatwootConfig } from '../config/chatwootConfig'; // Import the chatwoot configuration


const useChatwoot = (name, email, phoneNumber, locationId) => {

    useEffect(() => {
        // This function now gets the identifier directly from localStorage
        const getUserIdentifier = () => {
            // Use name and either email or phone number if available
            if (name && email) {
                return `${name} (${email})`;
            } else if (name && phoneNumber) {
                return `${name} (${phoneNumber})`;
            }
        
            // If only name is available, append a random 3-digit number to it
            if (name) {
                const randomThreeDigitNumber = Math.floor(Math.random() * 900) + 100;
                return `${name}-${randomThreeDigitNumber}`;
            }
        
            // If name is not available, use phone number or email if either is available
            if (phoneNumber) {
                return phoneNumber;
            } else if (email) {
                return email;
            }
        
            // Fallback identifier generation if no user info is available
            const adjectives = ["beautiful", "fantastic", "brilliant", "gorgeous", "smart", "incredible"];
            const animals = ["squirrel", "elephant", "tiger", "dragon", "horse", "dorphin", "shark", "whale", "zebra", ];
            const randomNumber = Math.floor(Math.random() * 1000);
            const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
            const randomAnimal = animals[Math.floor(Math.random() * animals.length)];
            return `${randomAdjective}-${randomAnimal}-${randomNumber}`;
        };
        
    
        // Function to initialize Chatwoot
        const initializeChatwoot = (token) => {

            const userIdentifier = getUserIdentifier();
            console.log("userIdentifier", userIdentifier);

            const loadChatwootScript = () => {
                const script = document.createElement('script');
                script.src = 'https://app.chatwoot.com/packs/js/sdk.js';
                script.async = true;
                document.body.appendChild(script);
                script.onload = () => {
                    window.chatwootSettings = {
                        position: "right",
                        locale: "pt",
                        useBrowserLanguage : true,
                        type: "standard",
                        launcherTitle: "Converse conosco",
                    };
                    window.chatwootSDK.run({
                        websiteToken: token,
                        baseUrl: 'https://app.chatwoot.com',
                    });
                };

                // Event listeners
                window.addEventListener("chatwoot:ready", function () {
                    // Custom logic when Chatwoot is ready
                });

                window.addEventListener("chatwoot:on-message", function (e) {
                    console.log("chatwoot:on-message", e.detail);
                });




            };


            if (window.$chatwoot) {
                window.$chatwoot.setUser(userIdentifier, {
                    email: email || undefined,
                    name: name || userIdentifier,
                    phone_number: phoneNumber || undefined
                });
                console.log("CHATWOOT", window.$chatwoot);
                if (token) {
                    window.$chatwoot.toggleBubbleVisibility("show")
                }else {
                    window.$chatwoot.toggleBubbleVisibility("hide")
                }
            } else {

                    loadChatwootScript();

                };
        }

        console.log('useChatwoot useEffect running');
        console.log('Chatwoot enabled:', chatwootConfig.chatwootEnabled);
        console.log('Location ID:', locationId);


        // Check if Chatwoot is enabled and set the appropriate token
        if (chatwootConfig.chatwootEnabled) {
            const token = chatwootConfig.chatwootTokens[locationId] || chatwootConfig.chatwootTokens['default'];
            console.log('Chatwoot token:', token);
            if (token && token !== '') {
                console.log('Chatwoot token:', token);
                initializeChatwoot(token);

            } else {
                console.log('Chatwoot token not found for location ID:', locationId);
                if (window.$chatwoot) {
                    window.$chatwoot.toggleBubbleVisibility("hide")
                }
            }
        }
    }, [email, name, phoneNumber, locationId]); 

};

export default useChatwoot;
