// application/frontend/src/components/CheckoutMbWayModal.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import '../themes/EllipsisAnimation.css';

const CheckoutMbWayModal = ({
  showModal,
  handleCloseModal,
  handleCloseModalAndNavigate,
  paymentRequested,
  paymentConfirmed,
  paymentFailed
}) => {
  const handleOkButtonClick = () => {
    handleCloseModalAndNavigate();
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Checkout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Verifique que a máquina está disponível.</h5>
        <h5>Verifique que a porta da máquina está aberta.</h5>
        <br />
        <h5>Aprove o pagamento na App MB WAY</h5>

        {/* Payment Requested and Awaiting Confirmation */}
        {paymentRequested && !paymentConfirmed && !paymentFailed && (
          <h5>
            Aguardando a confirmação do pagamento{' '}
            <span className="ellipsis"></span>
          </h5>
        )}

        {/* Payment Confirmed */}
        {paymentConfirmed && (
          <>
            <h5>
              Pagamento confirmado{' '}
              <FontAwesomeIcon
                icon={faCheck}
                style={{ color: 'green', marginLeft: '8px' }}
              />
            </h5>
            <h5>
              A máquina será ativada brevemente.
              <br />
              <br />
              <strong>
                Para iniciar, escolha o programa e pressione Start
              </strong>
              <br />
            </h5>
          </>
        )}

        {/* Payment Failed */}
        {paymentFailed && (
          <>
            <h5>O pagamento falhou ou expirou</h5>
          </>
        )}
      </Modal.Body>
      {paymentConfirmed && (
        <Modal.Footer>
          <Button variant="primary" onClick={handleOkButtonClick}>
            OK
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

CheckoutMbWayModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleCloseModalAndNavigate: PropTypes.func.isRequired,
  paymentRequested: PropTypes.bool.isRequired,
  paymentConfirmed: PropTypes.bool.isRequired,
  paymentFailed: PropTypes.bool.isRequired,
  //paymentStatus: PropTypes.string.isRequired,
};

export default CheckoutMbWayModal;