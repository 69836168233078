import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Row, Col } from 'react-bootstrap';
//import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import ProductCard from '../components/ProductCard';

import { fetchConfig } from '../getConfig'; 
import { fetchLocations } from '../lib/getLocationData';
import { AppConfigContext } from '../contexts/AppConfigContext'; 
import { useAppState } from '../contexts/AppStateContext';
import useChatwoot from '../hooks/useChatwoot';


function Store() {
  const fName = 'Store';
  const logPrefix = `<${fName}>`;
  console.log(logPrefix, ' Start');

  const { locationId } = useParams();
  const navigate = useNavigate();
  const { setState: setAppState } = useAppState();

  const [locationDefinitionData, setLocationDefinitionData] = useState(null);
  const [productsArray, setProductsArray] = useState([]);
  const [appConfig, setAppConfig] = useState(null);

  // Use the AppConfigContext
  const { productData } = useContext(AppConfigContext);

  console.log(logPrefix, 'locationId:', locationId);


  useEffect(() => {
    const loadConfig = async () => {
      try {
        const config = await fetchConfig();
        setAppConfig(config);
      } catch (error) {
        console.error("Error fetching app configuration:", error);
      }
    };
    loadConfig();
  }, []);


  // Populate productsArray based on productData and locationId
  useEffect(() => {
    const getLocationDefinitionData = async () => {
      const locations = await fetchLocations();
      const locDefinitionData = locations.find(loc => loc.laundry_id === locationId);
      if (locDefinitionData) {
        setLocationDefinitionData(locDefinitionData);
      }
    };
    getLocationDefinitionData();

    if (productData) {
      const products = productData[locationId];
      if (products) {
        setProductsArray(products);
      }
    }
  }, [productData, locationId]);

  console.log(logPrefix, ' End');

  useChatwoot( "", "", "", locationId);
  
  const handleCartClick = (productNumber) => {
    const selectedProduct = productData[locationId]?.find(p => p.number === productNumber);
    if (selectedProduct?.type === 'DRY') {
      navigate(`/dry-options/${locationId}/${productNumber}`);
    } else {
      setAppState(prevState => ({ ...prevState, cycles: 1 }));
      navigate(`/location/${locationId}/pay-options/${productNumber}`);
    }
  };
  
  if (!productsArray.length || !locationDefinitionData || !appConfig) {
    return <div><h3>A carregar...</h3></div>;
  }

  return (
    <>
      <h1 align="center" className="p-1 white-text">
        {locationDefinitionData.name}
      </h1>

      <Row className="g-2">
        {productsArray.map((product, idx) => (
          <Col xs={12} align="center" key={idx}>
            <ProductCard product={product} onCartClick={() => handleCartClick(product.number)} />
          </Col>
        ))}
      </Row>
    </>
  );
    
  
}

export default Store;
