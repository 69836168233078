// src/chatwootConfig.js
export const chatwootConfig = {
    chatwootEnabled: true,
    chatwootTokens: {
      '1': 'Sna6Pscr4djNDt2PbfRySK9H',
      '2': 'Sna6Pscr4djNDt2PbfRySK9H',
      // ... other laundromat tokens
      '101': '',
      'default': '', // Default token if laundromat not found
    },
  };
  