
/*
 * Filename: application/frontend/src/hooks/usePaymentProcessing.js
 * Description: CheckoutMbWay component
 * Author: Aires Marques
 * History:
 + 2023-10-22 - Checkout MbWay documented
 + 2024-10-08 - Simplified the payment processing logic
 * Notes:
*/


import { useState } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { backend } from '../authConfig';
import { useAppState } from '../contexts/AppStateContext';

export const usePaymentProcessing = ({ locationId, productNumber, phoneNumber, nif, email, name }) => {
  const { cycles, sessionUUID } = useAppState();

  const [paymentRequested, setPaymentRequested] = useState(false);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);

  // Map of payment status codes to messages (you can adjust as needed)
  const paymentStatusMapping = {
    "000": "Transação financeira concluída com sucesso",
    "020": "Transação financeira cancelada pelo utilizador",
    "023": "Transação financeira devolvida pelo Comerciante",
    "048": "Transação financeira cancelada pelo Comerciante",
    "100": "A operação não pôde ser concluída",
    "104": "Operação financeira não permitida",
    "111": "O formato do número de telemóvel não estava correto",
    "113": "O número de telemóvel usado como identificador não foi encontrado",
    "122": "Operação recusada ao utilizador",
    "123": "Transação financeira não encontrada",
    "125": "Operação recusada ao utilizador",
  };

  /**
   * submitPaymentRequest: Sends the MBWay payment request to the server with the new API structure.
   *
   * @returns {Promise<string>} - A promise that resolves to the payment ID.
   * @throws {Error} - If the payment request fails.
   */
  const submitPaymentRequest = async () => {
    const formattedLocationId = locationId.toString().padStart(2, '0');
    const formattedProductNumber = productNumber.toString().padStart(2, '0');

    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    const countryCode = parsedPhoneNumber.countryCallingCode;
    const nationalNumber = parsedPhoneNumber.nationalNumber;
    const formattedPhoneNumber = `${countryCode}#${nationalNumber}`;
    console.log('Formatted phone number:', formattedPhoneNumber);

    const params = {
      phone_number: formattedPhoneNumber,
      email: email,
      nif: nif,
      name: name,
      session_uuid: sessionUUID,
      machines: [
        {
          laundry_id: formattedLocationId,
          machine_id: formattedProductNumber,
          number_of_impulses: cycles
        }
      ]
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    };

    try {
      const response = await fetch(`${backend.url_post_payrequest}`, requestOptions);
      setPaymentRequested(true);

      if (!response.ok) {
        throw new Error('Failed to create MBWay payment');
      }
      const responseData = await response.json();
      const paymentId = responseData.IdPedido || responseData.payment_id;
      console.log('Payment ID:', paymentId);
      return paymentId;
    } catch (error) {
      console.error('Error:', error);
      setPaymentFailed(true);
    }
  };

  /**
   * pollPaymentConfirmation: Polls the server for the MBWay payment confirmation.
   *
   * @param {string} paymentId - The payment ID to check the confirmation for.
   */
  const pollPaymentConfirmation = async (paymentId) => {
    console.log("Polling payment confirmation for payment ID:", paymentId);

    const startTime = Date.now(); // Record the start time

    const poll = async () => {
      const elapsedTime = Date.now() - startTime; // Elapsed time in milliseconds

      // Determine polling interval based on elapsed time
      let delay;
      if (elapsedTime < 60000) {
        // First minute: poll every 2 seconds
        delay = 2000;
      } else if (elapsedTime < 120000) {
        // Second minute: poll every 5 seconds
        delay = 5000;
      } else {
        // After two minutes: poll every 10 seconds
        delay = 10000;
      }

      try {
        const queryParams = new URLSearchParams({ payment_id: paymentId }).toString();
        const response = await fetch(`${backend.url_get_payrequest}?${queryParams}`);
        
        if (!response.ok) {
          throw new Error('Failed to poll MBWay payment');
        }

        const responseData = await response.json();
        const paymentStatusCode = responseData.Estado;
        console.log("Response Data:", responseData);

        setPaymentStatus(paymentStatusCode);

        // "123" might indicate "transaction not found" or "pending"
        if (paymentStatusCode !== "123") { 
          if (paymentStatusCode === "000") {
            console.log("Payment confirmed:", responseData);
            setPaymentConfirmed(true);
          } else {
            console.log("Payment failed:", paymentStatusMapping[paymentStatusCode] || 'Status desconhecido');
            setPaymentFailed(true);
          }
          // Stop polling since payment is confirmed or failed
          return;
        } else {
          // Payment is still pending, continue polling after the delay
          setTimeout(poll, delay);
        }
      } catch (error) {
        console.error('Error:', error);
        // Optionally, you might want to stop polling on error
        setPaymentFailed(true);
      }
    };

    // Start the polling
    poll();
  };

  return {
    paymentRequested,
    setPaymentRequested,
    paymentConfirmed,
    setPaymentConfirmed,
    paymentFailed,
    setPaymentFailed,
    paymentStatus,
    setPaymentStatus,
    submitPaymentRequest,
    pollPaymentConfirmation
  };
};